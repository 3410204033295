@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');

html {font-size: 100%;} /*16px*/

body {
  background: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: #000000;
}

p {margin-bottom: 0.3rem;
@include phone{
  text-align: justify;
  font-size: 14px;
}
}

h1, h2, h3, h4, h5 {
  margin: 3rem 0 1.38rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.3;
}

h1 {
  font-weight: lighter !important;
  text-transform: uppercase;
  margin-top: 0;
  font-size: 4.209rem;
  
}
strong{
  font-weight: bolder !important;
}

h2 {font-size: 3.157rem;}

h3 {font-size: 2.369rem;}

h4 {font-size: 1.777rem;}

h5 {font-size: 1.333rem;}

small, .text_small {font-size: 0.7rem;}

label{ font-weight: lighter !important;
  font-size: 1rem;
  @include tablet {font-size:1.2rem};
  @include phone{font-size: 0.9rem;}
}

input{
  padding: 0.3rem;
 text-align: center;
  border-radius: 6px;
  border: none;
  color: #ae006e;
  
  
}

button{
  color: white;
  background-color: #ae006e;
  padding: 1rem;border: none;
  padding: 0.5rem;
  border-radius: 8px;
  width: 100%;
  max-width: 220px;
  font-weight: lighter !important;
  margin:auto 0.5rem 0.5rem auto;
  @include phone{
    margin: 0.5rem auto;
  }
  &:hover{
    
    cursor: pointer;
    background-color: #c7007f;
  }
}


.titulo{ color:#455ba8; font-weight: bolder; text-transform: uppercase;}


