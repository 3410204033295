@import './libs/mixins';
@import './libs/type';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: rgb(250, 250, 250);
}

header {
  width: 100%;
  max-width: 100vw;
  height: auto;
  min-height: 42vh;
  margin-bottom: 2rem;
  background-color: rgb(182, 182, 182);
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 1rem;
  background-image: url('../assets/img/header-img.png');
  background-size: cover;
  background-position: right;

  h1 {
    margin-left: 9%;
    color: grey;

    @include tablet {
      margin-left: 10px;
      font-size: 2rem;
    }

    @include phone {
      margin-left: 0;
      margin-bottom: 0;
      font-size: 1.4rem;
    }

    strong {
      color: #ae006e;
    }
  }

  @include phone {
    min-height: 150px;
    margin-bottom: 5px;
    background-image: url('../assets/img/header-img-r.png');
  }
}

.app-contenedor {
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-flow: column;
  margin: auto;
  max-width: 1080px;
  width: 100vw;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  box-sizing: border-box;

  .calculadora {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    width: 100%;
    height: auto;
    border-radius: 8px 8px 0px 0px;
    background-color: #eacbdb;

    input {
      width: 120px;
      margin: 5px;
    }

    form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      width: 100%;
      height: auto;
    }

    .contenedor-form {
      width: 100%;
      height: auto;
      display: flex;
      flex-flow: column;
      .bloque-rosa {
        padding: 1rem;
        display: flex;
        justify-content: space-evenly;
        align-items: initial;
        flex-flow: row;
        @include phone {
          flex-flow: column;
        }
        .botones-form,
        .datos-usuario {
          display: flex;
          flex-flow: column;
          flex: 1;
          justify-content: space-evenly;
          align-items: center;

          .group-usuario {
            display: flex;
            padding: 0.7rem;
            width: 100%;

            justify-content: space-between;
            align-items: center;
            label {
              font-weight: normal !important;
              color: #3b3b3bc9;
              min-width: 45px;
            }
            div {
              p {
                color: #ae006e;
              }
              input {
                margin: 0 0.5rem;
                &::placeholder {
                  color: #ae006e;
                }
                width: 100%;
                max-width: 205px;
              }
              width: 100%;
              display: flex;
            }
          }
        }
      }
      .outline {
        border: 1px solid #ae006e;
        background-color: transparent;
        color: #ae006e;
        cursor: pointer;
        &:hover {
          background-color: #ae006e;
          color: white;
        }
      }
      .bloque-azul {
        padding: 1rem;
        background-color: #455ba8;
        display: flex;
        color: white;
        @include phone {
          flex-flow: column;
        }
        .datos-bsa {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;

          div {
            display: flex;
          }
          @include phone {
            margin-bottom: 1rem;
            label {
              font-size: 0.8rem;
            }
          }
        }
        .datos-viales {
          display: flex;
          flex: 1;
          .caja-azul {
            font-weight: bold;
            color: #455ba8;
            border-radius: 6px;
            background-color: #d3d5eb;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            @include phone {
              flex-flow: column;
            }
            .grupo-viales {
              margin: 0.5rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .titulo-viales {
              transform: translateY(2px);
              justify-content: flex-start;
              text-align: left;
              margin-bottom: 5px;
              display: flex;
              flex-flow: column;
              line-height: 1;
            }
          }
          @include phone {
            flex-flow: column;
          }
        }
      }
      @include phone {
        display: flex;
        flex-flow: column;
      }
    }
  }

  .datos {
    background-color: #455ba8;
    width: 100%;
    height: auto;
    padding: 1rem;
  }

  .logos-footer {
    width: 100%;
    background-color: white;
  }

  .informacion {
    background-color: white;
    padding: 2rem;
    font-weight: lighter;
    display: flex;
    flex-flow: column;
    margin-bottom: 1rem;
    border-radius: 8px;

    .tabla {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;

      table {
        color: #455ba8;
        border-collapse: collapse;
        border: 1;
        width: 100%;
        max-width: 600px;
        gap: 0px;

        tr {
          border: 1px solid #455ba8;
        }

        td {
          border: 1px solid #455ba8;
          text-align: center;
        }
      }

      margin-bottom: 1rem;
    }
  }

  .formulas {
    border-radius: 8px;
    font-weight: lighter;
    width: 100%;
    background-color: white;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    justify-content: center;

    .contenedor-formulas {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;

      @include phone {
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      strong {
        font-size: 0.8rem;
      }
    }

    .formula {
      p {
        margin-bottom: none !important;
      }

      display: flex;
      align-items: flex-start;
      flex-flow: column;
      justify-content: center;
    }
  }

  .logos-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row;
    width: 100%;
    padding: 1rem;

    img {
      max-height: 80px;
    }

    @include phone {
      img {
        max-height: 50px;
      }
    }
  }

  .color {
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(69, 91, 168, 1) 0%,
      rgba(155, 31, 98, 1) 100%
    );

    height: 1rem;
  }
}

br {
  display: none;

  @include phone {
    display: block;
  }
}

.menos {
  max-width: 100px;
}

.frase p {
  margin: 16px 0 !important;
}

.legales {
  &:nth-of-type(1) {
    margin-top: 1rem;
  }

  text-align: justify;
  font-weight: lighter;
}
.crc {
  margin-top: 0.5rem;
  span {
    font-weight: lighter !important;
  }
}

.group-usuario-ajuste {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  label {
    font-weight: bolder !important;
    color: #455ba8;
    margin: 0 0.5rem 0.5rem 0.5rem;
  }
  #dosis {
    width: 100%;
    max-width: 220px;
    border: none;
    color: #455ba8;
    padding: 0.5rem;
    background-color: white;
    border-radius: 5px;
  }
}
